html,
#root {
    overflow-x: hidden;
    background-color: white;
    box-sizing: border-box;
}

#root {
    position: relative;
    flex: 1;
    height: calc(var(--vh, 1vh) * 100);
}

body {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

.bg-sky {
    background-image: url('../assets/images/backgroud-sky.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}
.bg-dark {
    background: #000;
}
.bg-light {
    background: #f5f5f5;
}

.main-container {
    position: relative;
    flex: 1;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 500px;
    overflow: hidden;
    width: 100%;
    margin: auto;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #9d9dad;
}

::-webkit-scrollbar-button {
    display: none;
}

@keyframes SlideBottomToTop {
    from {
        margin-top: calc(var(--vh, 1vh) * 100 + 100px);
    }
    to {
        margin-top: 0;
    }
}

@keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes ColorSuccess {
    from {
        color: #666666;
    }
    to {
        color: #4caf50;
    }
}
@keyframes ColorError {
    from {
        color: #666666;
    }
    to {
        color: #ef5350;
    }
}
